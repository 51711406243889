@import 'function.scss';

.life-at-ambros-title {
    h3 {
        letter-spacing: -2px;
        font-family: 'SVN-Poppins-ExtraBold';
        font-style: normal;
        font-weight: 800;
        font-size: betterClamp(28, 73);
        line-height: betterClamp(46, 124);
        color: var(--text-content-primary);
    }
    h1 {
        letter-spacing: -2px;
        font-family: 'SVN-Poppins-ExtraBold';
        font-style: normal;
        font-weight: 800;
        font-size: betterClamp(54, 136);
        line-height: betterClamp(46, 124);
        color: var(--text-accent-primary);
    }
}

.life-at-ambros {
    .list-image {
        .image {
            width: calc(50% - 12px);
        }
    }
    .heading-6 {
        font-size: betterClamp(16, 20);
        line-height: betterClamp(24, 28);
    }
    h3.heard-title {
        font-family: 'SVN-Poppins-ExtraBold';
        font-style: normal;
        font-weight: 700;
        font-size: betterClamp(28, 58);
        line-height: betterClamp(36, 68);
        color: var(--text-content-primary);
        letter-spacing: -2px;
    }
    .card-life {
        background: linear-gradient(121.24deg, #4169e1 31.12%, #6b41e1 107.23%);
        background-size: 100% 100%;
        background-repeat: no-repeat, no-repeat;
        background-position: 0% 0%;
    }
}
