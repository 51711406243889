@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'scss/theme';
@import 'scss/typography';
@import 'scss/common';
@import 'scss/animate';
@import 'scss/button';
@import 'scss/field';
@import 'scss/header';
@import 'scss/home';
@import 'scss/career';
@import 'scss/life-at-ambros';
@import 'scss/light-mode';
@import 'scss/dark-mode';
@import 'scss/responsive';

@layer base {
    html {
        font-family: 'SVN-Poppins-Regular', system-ui, sans-serif;
    }
}

@font-face {
    font-family: 'SVN-Poppins-ExtraBold';
    src: url('assets/fonts/SVN-Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'SVN-Poppins-Bold';
    src: url('assets/fonts/SVN-Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'SVN-Poppins-Regular';
    src: url('assets/fonts/SVN-Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'SVN-Poppins-Black';
    src: url('assets/fonts/SVN-Poppins-Black.ttf') format('truetype');
}
