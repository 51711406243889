:root {
    // color palette
    --text-content-primary: #ffffff;
    --text-content-secondary: #a8abb2;
    --text-accent-primary: #4169e1;

    --text-teal: #67ebff;
    --text-link: #1890ff;
    --text-blue: #00aeef;
    --text-purple: #7e1fff;
    --text-always-light-primary: #fff;
    --text-dark: #000;
    --text-white: #fff;
    // bg
    --bg-content-primary: #ffffff;
    --bg-header: #000;
    --bg-primary: #26282c;
    --bg-accent-primary: #4169e1;
    --bg-accent-secondary: rgba(65, 105, 225, 0.24);
    --bg-purple: #7e1fff;
    --bg-secondary: rgba(225, 235, 255, 0.1);
    --bg-always-dark-primary: #131416;
    --bg-elevation: #323438;
    --bg-line-primary: rgba(207, 219, 255, 0.08);
    --bg-quaternary: #000000;
    --bg-terny-primary: #171a22;
    --bg-scrimPrimary: rgba(0, 0, 0, 0.6);
    --bg-button-menu-bar: rgba(225, 235, 255, 0.1);
    --bg-white: #fff;
    --bg-close: #fff;
    --bg-slider: rgba(0, 0, 0, 0.4);

    // border
    --border-primary: rgba(207, 219, 255, 0.08);
    --border-product-info: rgb(127, 120, 172);
    --border-line-primary: rgba(207, 219, 255, 0.08);
    --border-teal: #67ebff;
    --border-accent-primary: #4169e1;
    --border-slider-dot: rgba(255, 255, 255, 0.3);

    // content
    --content-primary: #fff;
    --content-secondary: #a8abb2;
    --content-inverse-primary: #131416;
    --content-accent-primary: #4169e1;

    // line
    --line-primary: rgba(207, 219, 255, 0.08);

    // box shadow
    --s1-top: rgba(0, 0, 0, 0.7);
    --s3-top: rgba(0, 0, 0, 0.5);
}

[data-theme='light'] {
    // color palette
    --text-content-primary: #131416;
    --text-content-secondary: #797588;
    --text-accent-primary: #4169e1;
    --text-teal: #4fdff4;
    --text-link: #1890ff;
    --text-blue: #00aeef;
    --text-purple: #7e1fff;
    --text-always-light-primary: #fff;
    --text-dark: #000;
    --text-white: #fff;
    // bg
    --bg-content-primary: #ffffff;
    --bg-header: #000;
    --bg-primary: #fff;
    --bg-accent-secondary: rgba(65, 105, 225, 0.12);
    --bg-accent-primary: #4169e1;
    --bg-purple: #7e1fff;
    --bg-secondary: rgba(31, 27, 84, 0.05);
    --bg-always-dark-primary: #131416;
    --bg-line-primary: rgba(0, 0, 0, 0.08);
    --bg-quaternary: #fff;
    --bg-terny-primary: #f5f7fd;
    --bg-scrimPrimary: rgba(0, 0, 0, 0.6);
    --bg-elevation: #fff;
    --bg-button-menu-bar: rgba(225, 235, 255, 0.1);
    --bg-white: #fff;
    --bg-close: #000;
    --bg-slider: rgba(0, 0, 0, 0.4);
    // border
    --border-primary: rgba(0, 0, 0, 0.08);
    --border-product-info: rgb(127, 120, 172);
    --border-line-primary: rgba(0, 0, 0, 0.08);
    --border-teal: #67ebff;
    --border-accent-primary: #4169e1;
    --border-slider-dot: rgba(255, 255, 255, 0.3);

    // content
    --content-primary: #131416;
    --content-secondary: #797588;
    --content-always-light-primary: #fff;
    --content-always-dark-primary: #131416;
    --content-inverse-primary: #fff;
    --content-accent-primary: #4169e1;
    --content-link-primary: #096dd9;

    // line
    --line-primary: rgba(0, 0, 0, 0.08);

    // box shadow
    --s1-top: rgba(47, 49, 54, 0.1);
    --s3-top: rgba(47, 49, 54, 0.1);
}
