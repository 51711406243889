.btn {
    border-radius: 8px;
    padding: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    span {
        color: var(--text-always-light-primary);
    }
    img {
        display: block;
        margin-left: 9px;
    }
    &.primary {
        background-color: var(--bg-accent-primary);
        color: var(--text-always-light-primary);
    }
    &.light {
        background: var(--bg-white);
        border-radius: 8px;
        span {
            color: var(--text-accent-primary);
        }
        svg {
            fill {
                color: var(--text-accent-primary);
            }
        }
    }
}

.buttonSimple {
    background-color: var(--bg-accent-primary);
    border-radius: 8px;
    padding: 14px 16px;
    outline: 0;
    border: 0;
    cursor: pointer;
    h6 {
        color: #fff;
    }
}
