.selectDefault {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.textFieldSimple {
    width: 100%;

    input,
    textarea {
        background-color: var(--bg-primary);
        border: 1px solid var(--line-primary);
        border-radius: 8px;
        width: 100%;
        height: 48px;
        padding: 12px 16px;
        margin-top: 4px;
    }

    textarea {
        resize: none;
        min-height: 72px;
    }
}
