[data-theme='light'] {
    .text-border {
        border-color: var(--border-accent-primary);
        color: var(--text-accent-primary);
    }
    .accessible-useful span:not(.and) {
        color: var(--text-accent-primary);
    }
    .email-input-form input {
        background-color: var(--bg-white);
    }
    div.product-basic.read-and-learn div.product-info h3 span {
        color: #67ebff;
    }
    .themeControl .themeControl_box:not(.active) {
        svg path {
            fill: var(--bg-primary);
        }
    }
    .text-border span {
        border: 0.3px solid var(--border-accent-primary);
    }
    .section.banner {
        h3.left-title,
        h1.heading-1-extra-bold {
            color: var(--text-dark);
        }
    }
    .iconSvg_white {
        path {
            fill: var(--content-primary);
        }
    }
    .header.header_mobile {
        ul li a {
            color: var(--text-white);
        }
    }
}
