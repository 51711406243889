@import 'function.scss';
.header {
    display: flex;
    left: 0;
    max-width: 100%;
    padding: 0;
    position: fixed;
    z-index: 3;
    height: auto;
    top: 0;
    right: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    height: 64px;
}
.header_stage {
    height: 64px;
}

.header_menu_nav {
    ul li a {
        position: relative;
        z-index: 2;
        &.active {
            color: var(--text-teal);
        }
    }
}
.header-bgWhite {
    background-color: var(--bg-white);
}
.themeControl_box {
    border-radius: 6px;
    padding: 4.88px;
}
.themeControl_box.active {
    background-color: var(--bg-accent-primary);
}
// icon menu
.header_iconMenu {
    align-items: center;
    background-color: var(--bg-secondary);
    border-radius: 50%;
    cursor: pointer;
    flex-direction: column;
    height: 40px;
    justify-content: center;
    position: relative;
    transition: all 0.3s ease-in-out;
    width: 40px;
}
.header_iconMenu .header_iconMenu_line {
    margin: 4px 0;
}
.header_iconMenu .header_iconMenu_line,
.header_iconMenu:after,
.header_iconMenu:before {
    background-color: var(--content-primary);
    border-radius: 1px;
    content: '';
    display: block;
    height: 2px;
    transition: all 0.3s ease-in-out;
    width: 20px;
}
.header-bgDark {
    .header.header_mobile {
        background-color: var(--bg-header);
    }
}
.header.header_mobile .header_iconMenu {
    background-color: var(--bg-button-menu-bar);
    .header_iconMenu_line,
    &::after,
    &::before {
        background-color: var(--bg-white);
    }
}
.header_iconMenu.is-show .header_iconMenu_line {
    opacity: 0;
}
.header_iconMenu.is-show::before {
    position: absolute;
    transform: rotate(45deg);
}
.header_iconMenu.is-show::after {
    position: absolute;
    transform: rotate(-45deg);
}
.header_logo {
    margin-right: betterClamp(24, 175.5, 800);
}
.header-bgDark .header {
    background-color: var(--bg-primary);
}
.split {
    background-color: var(--bg-line-primary);
    width: 1px;
    height: 16px;
}
