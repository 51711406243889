@import 'function.scss';

.accessible-useful span:not(.and) {
    color: var(--text-teal);
}

.technology .title h1 {
    margin-top: calc(calc(#{betterClamp(0, 32)}) * -1);
}

.left-title {
    padding-left: betterClamp(16, 83);
}
.text-border {
    span:nth-child(1) {
        top: 0;
        left: 0;
        transform: translate(-50%, -50%);
    }
    span:nth-child(2) {
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
    }
    span:nth-child(3) {
        bottom: 0;
        left: 0;
        transform: translate(-50%, 50%);
    }
    span:nth-child(4) {
        bottom: 0;
        right: 0;
        transform: translate(50%, 50%);
    }
}
// Our products
.product-basic {
    background-image: url('../assets/background/image11.png');
    background-size: 100% 100%;
    background-repeat: no-repeat, no-repeat;
    background-position: 0% 0%;
    min-height: 640px;
    border: 1px solid var(--border-primary);
    border-radius: 8px;
    .product-info {
        backdrop-filter: blur(6px);
        background: rgba(255, 255, 255, 0.1);
        position: relative;
        min-width: 420px;
        max-width: 420px;
        height: 368px;
        margin-top: 136px;
        margin-left: 64px;
        padding: 32px;
        padding-left: 27px;
        border: 1px solid var(--border-product-info);
        clip-path: polygon(0 0, 100% 0, 100% 80%, 80% 100%, 0 100%);
        .displayXXLarge {
            font-size: betterClamp(28, 48, 650);
            line-height: betterClamp(36, 56, 650);
            color: var(--text-always-light-primary);
        }
        .bodyXXLarge {
            font-size: betterClamp(14, 20, 650);
            line-height: betterClamp(20, 28, 650);
            opacity: 0.7;
            color: var(--text-always-light-primary);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            background: var(--border-product-info);
            width: 35.284271%;
            height: 1px;
            bottom: 11%;
            right: -9%;
            transform: rotate(-41deg);
        }
    }
}
.product-banner {
    background-image: url('../assets/background/image11.png');
}
.read-and-learn {
    background-image: url('../assets/background/img-headbanner-dark.png');
}
.product-image {
    margin-right: betterClamp(16, 73, 1340);
}
// Core value
.list-core-value {
    --item: 3;
    --radius: 8px;
    display: grid;
    grid-template-columns: repeat(var(--item), 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    border: 1px solid var(--border-primary);
    border-radius: var(--radius);
    padding: 2px;
    opacity: 0;
    .core-value {
        border-right: 1px solid var(--border-primary);
        border-bottom: 1px solid var(--border-primary);
    }
    .core-value:nth-child(3n + 3) {
        border-right: none;
    }
    .core-value:nth-child(n + 4) {
        border-bottom: none;
    }
    .core-value:last-child .core-icon {
        background-color: var(--bg-accent-secondary);
    }
}

// Industry Quote
.list-industry-quotes {
    .industry-quote-item {
        height: 323px;
        max-width: 285px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .quote {
            position: relative;
            img {
                height: 30px;
            }
            &::before {
                content: '';
                background-color: var(--bg-accent-primary);
                display: block;
                position: absolute;
                left: 0;
                top: -16px;
                width: 30px;
                height: 3px;
            }
            h3,
            i {
                font-family: 'SVN-Poppins-Bold';
                font-style: normal;
                font-weight: 800;
                font-size: 24px;
                line-height: 30px;
                color: var(--text-content-primary);
            }
            i {
                font-style: italic;
                padding-right: 4px;
            }
            h3.purple {
                color: var(--text-purple);
            }
            .text-blue {
                color: var(--text-blue);
            }
        }
    }
    .industry-quote-item:nth-child(3) {
        .quote {
            &::before {
                top: -11px;
            }
        }
    }
    .industry-quote-item:nth-child(2) {
        .quote {
            &::before {
                top: -24px;
            }
        }
    }
}

// email box
.email-notification {
    border: 2px solid var(--border-line-primary);
    padding: 40px 48px;
}
.placeholder-content-secondary-dark::placeholder {
    color: var(--text-content-secondary);
}

.email-input-form {
    input {
        background-color: var(--bg-elevation);
        border: 1px solid var(--border-primary);
        box-shadow: 0px 12px 16px rgba(0, 0, 0, 0.05);
        border-radius: 16px;
    }
    button {
        font-family: 'SVN-Poppins-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }
}
.check-box {
    input[type='checkbox']:checked {
        background-color: var(--bg-accent-primary);
        background-image: url('../assets/images/checked.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 60%;
        border-color: transparent;
    }
}

// industry quotes slider
ul.list-dot {
    display: flex;
    justify-content: center;
    column-gap: 6px;
    margin-top: 0px;
    li {
        background-color: var(--bg-secondary);
        height: 8px;
        display: block;
        width: 24px;
        border-radius: 999px;
        margin: 0;
        &.slick-active {
            background-color: var(--bg-accent-primary);
        }
    }
    li button::before {
        display: none;
    }
}
.slick-dots {
    bottom: -32px;
}

.peoples {
    .image1,
    .image2,
    .image7,
    .image8,
    .image5 {
        width: betterClamp(64, 120);
        height: betterClamp(64, 120);
    }
    .image3,
    .image6 {
        width: betterClamp(134, 248);
        height: betterClamp(64, 120);
    }
    .image4 {
        width: betterClamp(64, 120);
        height: betterClamp(134, 248);
    }
    .image9 {
        width: betterClamp(134, 248);
        height: betterClamp(134, 248);
    }

    $time-animation: 0.75s;
    $time-delay: 0.5s;
    $time-opacity-show: 0.5s;
    .image4 {
        animation: opacityAnimation1 $time-opacity-show, opacityAnimation 1s;
    }
    .image2 {
        animation: slideInLeft;
        animation-duration: $time-animation;
        animation-delay: 0s;
    }
    .image1 {
        animation: slideInLeft $time-animation $time-delay * 1,
            opacityAnimation1 $time-opacity-show,
            opacityAnimation100 $time-opacity-show $time-delay * 1;
    }
    .image3 {
        animation: slideInLeft $time-animation $time-delay * 2,
            opacityAnimation1 $time-opacity-show * 2,
            opacityAnimation100 $time-opacity-show $time-delay * 2;
    }
    .image6 {
        animation: slideInUp $time-animation $time-delay * 3,
            opacityAnimation1 $time-opacity-show * 3,
            opacityAnimation100 $time-opacity-show $time-delay * 3;
    }
    .image5 {
        animation: slideInUp $time-animation $time-delay * 4,
            opacityAnimation1 $time-opacity-show * 4,
            opacityAnimation100 $time-opacity-show $time-delay * 4;
    }
    .image7 {
        animation: slideInRight;
        animation-duration: $time-animation;
        animation-delay: 0s;
    }
    .image8 {
        animation: slideInRight $time-animation $time-delay * 1,
            opacityAnimation1 $time-opacity-show * 1,
            opacityAnimation100 $time-opacity-show $time-delay * 1;
    }
    .image9 {
        animation: slideInUp $time-animation $time-delay * 2,
            opacityAnimation1 $time-opacity-show * 2,
            opacityAnimation100 $time-opacity-show $time-delay * 2;
    }
    img:hover {
        transition: all 0.5s ease-in-out;
        -webkit-transform: scale3d(1.15, 1.15, 1.15);
        transform: scale3d(1.15, 1.15, 1.15);
    }
}
.product-animation-1,
.product-animation-2 {
    .product-info {
        opacity: 1;
        animation: slideInLeft, opacityAnimation100;
        animation-delay: 0s;
        animation-duration: 0.5s;
    }
}

.core-value-animation {
    .list-core-value {
        opacity: 1;
        $time-animation: 0.3s;
        $time-delay: 0.3s;
        $time-opacity-show: 0.3s;
        @for $i from 1 through 6 {
            .core-value:nth-child(#{$i}) {
                animation: opacityAnimation1 $i * $time-opacity-show 0s,
                    zoomIn $time-animation $time-delay * $i;
            }
        }
    }
}
