/* === start laptop === */
@media screen and (min-width: 1024px) and (max-width: 1320px) {
    .container {
        padding: 0 betterClamp(26, 83, 900);
    }
    .heading-1-extra-bold {
        font-size: betterClamp(52, 78, 800);
        line-height: betterClamp(78, 132, 800);
    }
    .heading-3-bold {
        font-size: betterClamp(28, 40, 800);
        line-height: betterClamp(42, 72, 800);
    }
    .heading-3-home {
        font-size: betterClamp(28, 40, 800);
        line-height: betterClamp(42, 72, 800);
    }
    .and {
        padding: 5px;
    }
}

@media screen and (max-width: 1200px) {
    .product-basic {
        padding: 30px 0;
        min-height: auto;
        > div {
            align-items: center;
        }
        .product-image {
            max-width: 50%;
            margin-right: 1.5rem;
            margin-top: 30px;
        }
        .product-info {
            margin: 0;
            left: 1.5rem;
        }
    }
}

/* === start tablet === */
@media screen and (max-width: 993px) {
    .theme-box {
        display: none;
    }
    .header_iconMenu {
        display: flex;
    }
    .header_logo {
        width: fit-content;
        margin: 0;
    }
    .md-flex-col {
        flex-direction: column;
        text-align: center;
        align-items: center;
    }
    .header-container {
        position: relative;
        justify-content: flex-start;
        flex-direction: column;
        height: 0;
    }
    .header_theme {
        position: absolute;
        right: 0;
    }
    .header_menu_nav {
        width: 100%;
        margin-top: 18px;
        transition: all 0.3s ease-in-out;
        transform: translateX(-200%);
        ul {
            display: block;
        }
        ul li + li {
            padding-left: 0;
            padding-top: 16px;
        }
        ul li:last-child {
            padding-bottom: 4px;
        }
        .split {
            width: 0;
        }
    }
    .header_mobile {
        height: auto;
        transition: all 0.3s ease-in-out;
        background-color: var(--bg-header);
        .header-container {
            height: auto;
        }
        .header_menu_nav {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            ul {
                margin: 0;
            }
        }
    }
    .email-box {
        .desktop {
            display: none;
        }
        .mobile {
            display: block;
        }
        .input-form {
            width: 100%;
        }
        .email-notification {
            padding: 16px;
            border-width: 1px;
        }
    }
    .product-basic {
        .product-info {
            min-width: 300px;
            max-width: 343px;
            height: 330px;
            padding: 20px;
            .icon-mobile-download a {
                width: 45%;
            }
            &::after {
                bottom: 12%;
                right: -10%;
                transform: rotate(-44deg);
            }
        }
        .product-image {
            max-width: 40%;
        }
    }
}

/* === start mobile === */
@media (max-width: 799px) {
    .product-basic {
        .product-info {
            height: 303px;
        }
        &.read-and-learn {
            > div {
                flex-direction: column-reverse;
                height: 100%;
            }
            .product-image {
                max-width: 400px;
                width: 100%;
                margin: 0 auto;
                padding-top: 15px;
            }
        }
    }
    .read-and-learn .product-info {
        height: fit-content;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        left: 0;
        &::after {
            transform: rotate(-35deg);
        }
    }
    .product-banner .product-info {
        height: 245.53px;
        &::after {
            transform: rotate(-36deg);
        }
    }
    .list-core-value {
        grid-template-columns: repeat(2, 1fr);
        .core-value:nth-child(n + 1) {
            border-right: 1px solid var(--border-primary);
            border-bottom: 1px solid var(--border-primary);
        }
        .core-value:nth-child(2n + 2) {
            border-right: none;
        }
        .core-value:nth-child(n + 5) {
            border-bottom: none;
        }
    }
}
@media screen and (max-width: 767px) {
    .life-at-ambros {
        .list-image {
            .image {
                width: calc(50% - 6px);
            }
        }

        .life-at-ambros-title {
            h3 {
                letter-spacing: -0.784324px;
            }
            h1 {
                letter-spacing: -0.784324px;
            }
        }
        h3.heard-title {
            letter-spacing: 0px;
        }
        .card-life {
            .displayXLarge {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 8px;
            }
            .bodyLarge {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 12px;
            }
        }
    }
}

@media (max-width: 640px) {
    .portal {
        display: flex;
    }
    .selectDefaultScss ul {
        display: none;
    }
}

/* === start mobile small=== */
@media (max-width: 499px) {
    .list-core-value {
        grid-template-columns: repeat(1, 1fr);
        .core-value:nth-child(n + 1) {
            border-bottom: 1px solid var(--border-primary);
            border-right: none;
        }
        .core-value:last-child {
            border-bottom: none;
        }
        .core-value {
            .headingXLarge {
                font-size: 16px;
                line-height: 24px;
            }
            .bodyMedium {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
    .product-basic {
        height: 600px;
        display: flex;
        align-items: flex-end;
        padding: 16px;
        justify-content: center;
        .product-info {
            left: 0;
            padding: 16px;
        }
    }
    .read-and-learn {
        height: 608px;
        background-image: url('../assets/background/bg-product-mobile-2.png');
        > div {
            flex-direction: column-reverse;
        }
        .product-image {
            display: block;
            max-width: 100%;
            img {
                width: 326.39px;
                height: 284.69px;
            }
        }
    }
    .product-banner {
        background-image: url('../assets/background/bg-product-mobile-1.png');
    }
    .icon-mobile-download > div.flex {
        column-gap: 8px;
    }
    .product-basic .product-info .icon-mobile-download a {
        width: 50%;
        margin: 0;
    }
    .product-basic .product-info .icon-mobile-download a img {
        height: 42px;
    }
    .read-and-learn .product-info {
        height: 272.52px;
        &::after {
            transform: rotate(-40deg);
        }
    }
    .list-industry-quotes .industry-quote-item .quote::before {
        background-color: var(--text-teal);
    }
}
@media screen and (max-width: 399px) {
    .product-basic {
        .product-info {
            padding: 16px;
            .displayXXLarge,
            .icon-mobile-download,
            .bodyXXLarge {
                margin-bottom: 10px;
            }
        }
    }
    .read-and-learn .product-info {
        height: 240px;
        &::after {
            transform: rotate(-36deg);
        }
    }
    .displayXXLarge {
        letter-spacing: 0px;
    }
    .product-banner .product-info {
        width: 310px;
        height: fit-content;
        &::after {
            transform: rotate(-39deg);
        }
    }
}

@media screen and (max-width: 390px) {
    .read-and-learn .product-info {
        height: 260px;
        &::after {
            transform: rotate(-39deg);
        }
    }
}
