@import 'function.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,
body {
    overflow-x: hidden;
}

body {
    background-color: var(--bg-quaternary);
    color: var(--text-content-primary);
}

html::-webkit-scrollbar-track {
    background-color: var(--bg-primary);
}

html::-webkit-scrollbar {
    width: 5px;
    // background-color: #F5F5F5;
}

html::-webkit-scrollbar-thumb {
    background-color: #555;
}

.text-color-white {
    color: #fff !important;
}

.text-color-content-inverse-primary {
    color: var(--content-inverse-primary) !important;
}

.text-color-content-always-light-primary {
    color: var(--content-always-light-primary) !important;
}

.text-color-content-primary {
    color: var(--content-primary) !important;
}

.text-color-content-secondary {
    color: var(--content-secondary) !important;
}

.text-color-content-accent-primary {
    color: var(--content-accent-primary) !important;
}

.text-color-content-link-primary {
    color: var(--content-link-primary) !important;
}

.d-flex {
    display: flex;
}

.container {
    width: 1366px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 betterClamp(16, 83);
}
.wrapper {
    min-height: 720px;
    position: relative;
    z-index: 1;
    &::before {
        content: '';
        background-image: url('../assets/background/Ellipse188.png');
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 837px;
        height: 720px;
        background-size: 100%;
        background-repeat: no-repeat, no-repeat;
        background-position: 0% 0%;
        z-index: -9;
    }
}

.portal {
    position: fixed;
    z-index: 1300;
    inset: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    display: none;
}

.portal_blur {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0px;
    background-color: var(--bg-scrimPrimary);
    -webkit-tap-highlight-color: transparent;
    z-index: -1;
}

.portal_container {
    background-color: var(--bg-primary);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 16px 16px 0px 0px;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;

    .portal_options {
        position: relative;
        border: 0;
        background-color: transparent;
        box-shadow: 0 0 0 0 transparent;

        li {
            cursor: pointer;
        }
    }
}
.icon-add-more {
    border: 1.5px solid var(--text-content-primary);
    width: 6.67px;
    height: 6.67px;
    border-radius: 50%;
    position: relative;
    &::after,
    &::before {
        content: '';
        display: block;
        border: 1.5px solid var(--text-content-primary);
        width: 6.67px;
        height: 6.67px;
        border-radius: 50%;
        top: 50%;
        position: absolute;
    }
    &::before {
        transform: translate(-100%, -50%);
        left: -5px;
    }
    &::after {
        transform: translate(100%, -50%);
        right: -5px;
    }
}

.border-bottom {
    border-bottom: 1px solid var(--line-primary);
}
