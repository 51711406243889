@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.15, 1.15, 1.15);
        transform: scale3d(1.15, 1.15, 1.15);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes opacityAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes opacityAnimation100 {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
@keyframes opacityAnimation1 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
